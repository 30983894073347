var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AddToCompare',{attrs:{"compares":_vm.compares}}),_c('main',{staticClass:"main product-details-main"},[_c('PagesPath',{attrs:{"path-title1":"لیست محصولات","path-title2":"مقایسه","pathTo1":"/products","theme":"1"}}),_c('div',{staticClass:"container text-right mt-md-5 mt-3"},[(_vm.products)?[_c('div',{staticClass:"row"},[_vm._l((_vm.products),function(product){return _c('a',{key:product.id,staticClass:"compare-item",class:'col-' + _vm.col,attrs:{"href":_vm.$router.resolve({
                name: 'product.detail',
                params: {
                  name: ("" + (product.en_title ? product.en_title.replaceAll(' ','-') + '-' : 'detail-') + (product.id)),
                },
              }).href},on:{"click":function (e) { return _vm.goTo(e, {
                  name: 'product.detail',
                  params: {
                    name: ("" + (product.en_title ? product.en_title.replaceAll(' ','-') + '-' : 'detail-') + (product.id)),
                  },
                }); }}},[_c('img',{staticClass:"close",attrs:{"src":require('@/assets/images/close.png'),"alt":"Remove"},on:{"click":function($event){return _vm.remove(product)}}}),_c('img',{staticClass:"main-image",attrs:{"src":_vm.getImage(product.major_image, 'lg'),"alt":product.title}}),_c('h6',{staticClass:"text-black mt-2"},[_vm._v(" "+_vm._s(product.title)+" ")]),_c('button',{staticClass:"fontsize-small text-white-hover mx-auto mb-3\n             bg-color-theme px-1 py-1 px-sm-3 py-sm-2 text-white mt-sm-3 mx-auto d-inline-block d-none d-sm-block"},[_vm._v(" مشاهده "),(!_vm.mediaQueries.mobileSize)?[_vm._v("و خرید ")]:_vm._e()],2)])}),(_vm.products && _vm.products.length < (_vm.col == 4 ? 3 : 4))?_c('div',{staticClass:"add-compare",class:'col-' + _vm.col,on:{"click":_vm.addCompare}},[_c('img',{staticClass:"d-block mx-auto",attrs:{"width":_vm.col == 4 ? 100 : 200,"src":require('@/assets/images/add.png'),"alt":"افزودن کالای جدید"}}),_c('div',[_vm._v("برای افزودن کالا کلیک کنید")])]):_vm._e()],2),(_vm.specifications && _vm.specifications !== '')?_c('div',{staticClass:"compare-table"},_vm._l((_vm.specifications),function(item){return _c('div',{key:item.id,staticClass:"attribute"},[_c('div',{staticClass:"attribute-title"},[_c('span',[_vm._v(_vm._s(item.label))])]),_c('div',{staticClass:"row"},_vm._l((_vm.products),function(product,index){return _c('div',{key:index,staticClass:"value",class:'col-' + _vm.col},[_c('span',[_vm._v(_vm._s(_vm.getSpecificationValue(product, item)))])])}),0)])}),0):_vm._e()]:_c('div',{staticClass:"d-flex justify-content-center"},[_c('Loading')],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }